html,
body,
#root {
    margin: 0;
    padding: 0;
    border: none;
    background-color: black;
    height: 100%;
}

/* Stop browser from giving dfualt stylings to button */
a {
    text-decoration: none;
    color: inherit;
}

@font-face {
    font-family: 'Concert One';
    src: url('./assets/fonts/ConcertOne-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat.ttf') format('truetype');
}